import * as React from 'react'
import {Flex, Image, keyframes} from '@chakra-ui/react'

import meow from './meow.png'

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

const Home = () => {
  return (
    <Flex height="100vh" alignItems="center" justifyContent="center" overflow="hidden">
      <Image src={meow} animation={`${spin} infinite 2s linear`} />
    </Flex>
  )
}

export default Home
