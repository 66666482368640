import * as React from 'react'
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Avatar,
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Button,
} from '@chakra-ui/react'
import {useLocation} from 'react-router-dom'

type AppInfo = {
  name: string
}

type ScopeInfo = {
  name: string
}

const appInfos: Record<string, AppInfo> = {
  helloworld1: {
    name: 'Test App 1',
  },
  helloworld2: {
    name: 'Test App 2',
  },
}

const validClientIDs = [...Object.keys(appInfos)]

const scopeInfos: Record<string, ScopeInfo> = {
  openid: {
    name: 'Your unique identifier',
  },
  profile: {
    name: 'Your profile details',
  },
}

const validScopes = [...Object.keys(scopeInfos)]

const users = [
  {
    avatar: '/avatars/cat-1.png',
    id: 'uid1',
    name: 'Cat Catman',
  },
  {
    avatar: '/avatars/cat-2.png',
    id: 'uid2',
    name: 'Meow Meowski',
  },
]

const Authorize = () => {
  const location = useLocation()

  const search = React.useMemo(() => {
    const params = new URLSearchParams(location.search.slice(1))
    return [...params.entries()].reduce<Record<string, string>>((p, v) => {
      p[v[0]] = v[1]
      return p
    }, {})
  }, [location])

  const {
    appInfo,
    error: validationError,
    redirectURI,
    scopes,
  } = React.useMemo(() => {
    if (!validClientIDs.find((v) => v === search.client_id)) {
      return {
        error: `the client ID must be either ${validClientIDs.map((id) => `"${id}"`).join(' or ')}`,
      }
    }
    if (search.response_type !== 'code') {
      return {
        error: 'the response_type must be "code"',
      }
    }
    if (!search.scope) {
      return {
        error: 'missing "scope"',
      }
    }
    const scopes = search.scope.split(' ')
    if (!search.state) {
      return {
        error: 'missing "state"',
      }
    }
    for (const scope of scopes) {
      if (!validScopes.find((v) => v === scope)) {
        return {
          error: `scope "${scope}" is not supported`,
        }
      }
    }
    if (!scopes.find((v) => v === 'openid')) {
      return {
        error: '"openid" scope is required',
      }
    }
    if (!search.redirect_uri) {
      return {
        error: 'missing "redirect_uri"',
      }
    }

    return {
      appInfo: appInfos[search.client_id],
      redirectURI: search.redirect_uri,
      scopes,
    }
  }, [search])

  const blockBackground = useColorModeValue('white', 'gray.800')
  const selectedBlockBackground = useColorModeValue('gray.900', 'gray.700')

  const [selectedUser, setSelectedUser] = React.useState<string | null>(null)

  const scopesList = React.useMemo(() => scopes?.map((v) => scopeInfos[v]), [scopes])

  const [loading, setLoading] = React.useState<boolean>(false)
  const handleConfirm = React.useCallback(async () => {
    if (!redirectURI) {
      return
    }
    setLoading(true)
  }, [redirectURI])

  const handleReject = React.useCallback(() => {
    if (!redirectURI) {
      return
    }

    const url = new URL(redirectURI)
    url.searchParams.set('error', 'user_rejected')
    url.searchParams.set('error_description', 'User rejected the authentication attempt.')
    window.location.href = url.toString()
  }, [redirectURI])

  const postTarget = React.useMemo(() => {
    if (!selectedUser) {
      return ''
    }

    const url = new URL(window.location.href)
    url.searchParams.append('uid', selectedUser)
    return url.toString()
  }, [selectedUser])

  if (validationError) {
    return (
      <Container maxW="container.sm" my={8}>
        <Heading mb={4} size="md">
          Authorization failed
        </Heading>
        <Alert status="error" borderRadius={5} mb={4}>
          <AlertIcon />
          <AlertDescription>{validationError}</AlertDescription>
        </Alert>
      </Container>
    )
  }

  return (
    <Container maxW="container.sm" my={8}>
      <Heading mb={4} size="md">
        Welcome to CatHub! Choose your fighter:
      </Heading>
      {users.map((user) => (
        <Box
          key={user.id}
          mb={4}
          borderColor={user.id === selectedUser ? 'whiteAlpha.600' : 'whiteAlpha.300'}
          borderWidth="1px"
          rounded="lg"
          shadow="xl"
          position="relative"
          bg={user.id === selectedUser ? selectedBlockBackground : blockBackground}
          cursor="pointer"
          onClick={() => setSelectedUser(user.id)}
        >
          <Box p="6">
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar name={user.name} src={user.avatar} />
              <Text fontSize="xl" pl={2}>
                {user.name}
              </Text>
            </Stack>
          </Box>
        </Box>
      ))}
      <Heading mb={4} size="md">
        {appInfo?.name} is asking for:
      </Heading>
      {scopesList?.map((scope, i) => (
        <Box
          key={i}
          mb={4}
          borderColor="whiteAlpha.300"
          borderWidth="1px"
          rounded="lg"
          shadow="xl"
          position="relative"
          bg={blockBackground}
        >
          <Box p="6">
            <Text fontSize="md" pl={2}>
              {scope.name}
            </Text>
          </Box>
        </Box>
      ))}
      {!!validationError && (
        <Alert status="error" borderRadius={5} mb={4}>
          <AlertIcon />
          <AlertDescription>{validationError}</AlertDescription>
        </Alert>
      )}
      <Flex direction="row" alignItems="center" justifyContent="space-between">
        <form
          action={postTarget}
          method="post"
          style={{
            marginRight: '8px',
            width: '100%',
          }}
        >
          <Button
            type="submit"
            colorScheme="green"
            disabled={selectedUser === null}
            onClick={handleConfirm}
            isLoading={loading}
            w="100%"
          >
            Approve
          </Button>
        </form>
        <Button ml={2} w="100%" colorScheme="red" onClick={handleReject}>
          Reject
        </Button>
      </Flex>
    </Container>
  )
}

export default Authorize
